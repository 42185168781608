import axios from "axios";
import { request } from "@/utils/request";
// 查询文章
export function selectArticleManage(data) {
  return request({
    url: `/admin/articleManage/select`,
    method: "POST",
    data,
  });
}

// 新增文章
export function addArticleManage(data) {
  return request({
    url: `/admin/articleManage/add`,
    method: "POST",
    data,
  });
}

/* 修改文章 */
export function updateArticleManage(data) {
  return request({
    url: `/admin/articleManage/update`,
    method: "POST",
    data,
  });
}

// 删除文章
export function deleteArticleManage(data) {
  return request({
    url: `/admin/articleManage/delete`,
    method: "POST",
    data,
  });
}


/* 关于段落操作 */
// 查询文章段落
export function selectParagraph(data) {
  return request({
    url: `/admin/articleManage/selectParagraph`,
    method: "POST",
    data,
  });
}

// 新增文章段落
export function addParagraph(data) {
  return request({
    url: `/admin/articleManage/addParagraph`,
    method: "POST",
    data,
  });
}

/* 修改文章段落 */
export function updateParagraph(data) {
  return request({
    url: `/admin/articleManage/updateParagraph`,
    method: "POST",
    data,
  });
}

// 删除文章
export function deleteParagraph(data) {
  return request({
    url: `/admin/articleManage/deleteParagraph`,
    method: "POST",
    data,
  });
}


/* 设置推荐 */
export function updateRecommend(data) {
  return request({
    url: `/admin/articleManage/updateRecommend`,
    method: "POST",
    data,
  });
}
