export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
        { label: "状态", prop: "status", slots: "status", width: 70 },
    { label: "封面", prop: "img", slots: "img", width: 70 },
    { label: "文章名称", prop: "categoryName", width: 320 },
    // { label: "类型", prop: "type", slots: "type", width: 110 },
    // { label: "文章链接", prop: "link", width: 300 },
    { label: "标签", prop: "tags", slots: "tags", width: 300 },
    { label: "热度", prop: "hot", width: 100 },
    { label: "精选", prop: "recommend", slots: "recommend", width: 100 },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 220,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
