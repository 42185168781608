import axios from "axios";
import { request } from "@/utils/request";
// 查询一级分类
export function selectCategory(data) {
  return request({
    url: `/admin/articleCategory/selectCategory`,
    method: "POST",
    data,
  });
}

// 新增一级分类
export function addCategory(data) {
  return request({
    url: `/admin/articleCategory/addCategory`,
    method: "POST",
    data,
  });
}

/* 修改分类 */
export function updateCategory(data) {
  return request({
    url: `/admin/articleCategory/updateCategory`,
    method: "POST",
    data,
  });
}

// 删除分类
export function deleteCategory(data) {
  return request({
    url: `/admin/articleCategory/deleteCategory`,
    method: "POST",
    data,
  });
}

