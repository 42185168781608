<template>
  <div class="diseaseList">
    <!-- 筛选查询框 @change="selectGoodsByIdBtn"-->
    <el-form :inline="true">
      <el-button
        class="addBtn"
        type="primary"
        size="small"
        v-waves
        @click="handleAdd"
        >新增</el-button
      >
      <el-form-item label="分类: " style="margin: 0 20px" size="small">
        <el-select
          v-model="listQuery.categoryID"
          placeholder="请选择"
          clearable
        >
          <div v-for="item in categoryArr" :key="item.id">
            <el-option :label="item.categoryName" :value="item.id"></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="文章名称: " style="margin: 0 20px" size="small">
        <el-input
          v-model="listQuery.categoryName"
          placeholder="请输入文章名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="文章标签: " style="margin: 0 20px" size="small">
        <el-input
          v-model="listQuery.tags"
          placeholder="请输入文章标签"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        class="addBtn"
        type="primary"
        @click="getListData"
        size="small"
        v-waves
        >搜索</el-button
      >
    </el-form>
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <!-- 是否开启推荐-->
        <template slot="recommend" slot-scope="{ row }">
          <el-switch
            :value="row.recommend == 1"
            active-color="#afb86d"
            inactive-color="#ff4949"
            @change="recommendChange(row, $event)"
          >
          </el-switch>
        </template>

        <template slot="type" slot-scope="{ row }">
          <el-tag type="success" effect="dark" size="mini">
            {{ row.type == 1 ? "文章链接" : "自主上传" }}
          </el-tag>
        </template>
        <!-- <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="row.store_id" />
        </template> -->
        <template slot="img" slot-scope="{ row }">
          <el-image
            style="width: 30px; height: 30px"
            :src="row.img"
            :preview-src-list="[row.img]"
          >
          </el-image>
        </template>

        <template slot="status" slot-scope="{ row }">
          <el-tag
            class="theTagGreen"
            type="success"
            effect="dark"
            size="mini"
            v-if="row.status === 0"
          >
            显示
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else> 隐藏 </el-tag>
        </template>

        <template slot="tags" slot-scope="{ row }">
          <div v-if="row.tags">
            <el-tag
              class="theTagGreen"
              style="margin-right:5px"
              type="success"
              effect="dark"
              size="mini"
              v-for="(item, index) in tagsList(row.tags)"
              :key="index"
            >
              {{ item }}
            </el-tag>
          </div>

          <div v-else>暂无</div>
        </template>

        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEdit(row)"
              primary
              >编辑</el-button
            >
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEditParagraph(row)"
              primary
              v-if="row.type == 2"
              >自主上传</el-button
            >
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEditCode(row)"
              primary
              v-if="row.type == 3"
              >代码管理</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0)"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 产品提交表单 -->
      <el-dialog
        :title="inpitTypeName"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="120px">
          <el-form-item label="文章分类: " required>
            <el-select
              v-model="dialogForm.categoryID"
              placeholder="请选择文章分类"
            >
              <div v-for="item in categoryArr" :key="item.id">
                <el-option
                  :label="item.categoryName"
                  :value="item.id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="文章类型: " required>
            <el-select
              v-model="dialogForm.type"
              placeholder="请选择(默认为显示)"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="作者: " required>
            <el-select
              v-model="dialogForm.authorId"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in authorList"
                :label="item.name"
                :value="item.accountID"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属门店:" required>
            <el-select
              v-model="dialogForm.store_id"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in filterStoreList" :key="index">
                <el-option
                  :label="item.store_name"
                  :value="item.store_id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item> -->
          <el-form-item label="文章名称" required>
            <el-input v-model="dialogForm.categoryName" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述文字" required>
            <el-input
              v-model="dialogForm.detail"
              clearable
              type="textarea"
              :rows="5"
            ></el-input>
          </el-form-item>
          <el-form-item label="文章标签">
            <el-input
              v-model="dialogForm.tags"
              clearable
              placeholder="多标签请用逗号拼接，例如:艺术,生活,旅行,摄影 (会在网站文章尾部展示)"
              type="textarea"
            ></el-input>
          </el-form-item>

          <el-form-item label="文章链接" v-if="dialogForm.type == 1">
            <el-input
              v-model="dialogForm.link"
              clearable
              placeholder="若为自定义上传,则此处可以不填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否显示: ">
            <el-select
              v-model="dialogForm.status"
              placeholder="请选择(默认为显示)"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="文章热度">
            <el-input
              v-model="dialogForm.hot"
              clearable
              type="number"
            ></el-input>
          </el-form-item>

          <el-form-item label="封面地址" width="100">
            <el-input
              v-model="dialogForm.img"
              clearable
              placeholder="点击下方按钮可以快速上传"
            ></el-input>
          </el-form-item>

          <el-form-item label="封面预览" width="100">
            <div class="demo-image__preview">
              <el-image
                style="width: 100px; height: 100px"
                :src="dialogForm.img"
                :preview-src-list="[dialogForm.img]"
              >
              </el-image>
            </div>
          </el-form-item>

          <el-form-item label="上传">
            <el-upload
              class="upload-demo"
              :action="action"
              :multiple="true"
              :on-success="handleSuccess_cover"
              :file-list="fileList"
              list-type="picture"
              name="formData"
            >
              <el-button class="addBtn" size="small" type="primary"
                >上传文章主图</el-button
              >
              <div slot="tip" class="el-upload__tip">
                请上传jpg/png文件，500px X 500px, 会自动覆盖原来的图片
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 自主上传文章预览 -->
      <el-dialog
        title="自主上传文章预览"
        :visible.sync="dialogParagraphVisible"
        :close-on-click-modal="false"
        top="0"
        :fullscreen="true"
      >
        <el-button type="primary" size="small" v-waves @click="handleAddItem"
          >新增段落</el-button
        >

        <div class="detailsImgBox">
          <h2 style="text-align: center">{{ dialogForm.categoryName }}</h2>
          <div class="detailsImgBoxShow">
            <div
              v-for="(item, index) in paragraphData"
              :key="index"
              @click="handleEditItem(item)"
            >
              <h3 v-if="item.type === 1">{{ item.text }}</h3>
              <p v-if="item.type === 2">{{ item.text }}</p>
              <img
                :src="item.imgUrl"
                alt
                style="width: 50%"
                v-if="item.type === 3"
              />

              <video
                :src="item.imgUrl"
                controls="controls"
                style="width: 50%"
                v-if="item.type === 4"
              >
                您的浏览器不支持 video 标签。
              </video>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogParagraphVisible = false">返 回</el-button>
          <!-- <el-button type="primary" @click="updataImgBtn">确 定</el-button> -->
        </div>
      </el-dialog>

      <!-- 段落操作 -->
      <el-dialog
        title="操作段落"
        :visible.sync="itemEditVisible"
        :close-on-click-modal="false"
      >
        <el-form :model="paragraphObj">
          <el-form-item
            label="段落类型: "
            :label-width="formLabelWidth"
            required
          >
            <el-select
              v-model="paragraphObj.type"
              placeholder="请选择(默认为显示)"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in paragraphTypeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="" :label-width="formLabelWidth" width="100">
            <img
              v-if="
                paragraphObj.type == 3 ||
                  (paragraphObj.type == 3 && !paragraphObj.id)
              "
              :src="paragraphObj.imgUrl"
              alt
              style="width: 200px; border: 1px dotted rgb(93, 93, 93)"
            />
          </el-form-item>

          <el-form-item
            label=""
            :label-width="formLabelWidth"
            width="100"
            v-if="
              paragraphObj.type == 3 ||
                (paragraphObj.type == 3 && !paragraphObj.id)
            "
          >
            <el-upload
              class="upload-demo"
              :action="action"
              :multiple="false"
              :on-remove="handleRemove"
              :on-success="updateImgSuccess"
              :file-list="fileList"
              list-type="picture"
              name="formData"
            >
              <el-button size="small" type="primary">上传图片</el-button>
              <div slot="tip" class="el-upload__tip">
                建议上传jpg/png文件，500px X 500px,每次上传覆盖之前的
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            label="视频链接"
            :label-width="formLabelWidth"
            v-if="
              paragraphObj.type == 4 ||
                (paragraphObj.type == 4 && !paragraphObj.id)
            "
          >
            <el-input
              disabled
              v-model="paragraphObj.imgUrl"
              auto-complete="off"
              type="textarea"
              placeholder="上传后自动显示"
            ></el-input>
          </el-form-item>

          <el-form-item
            label=""
            :label-width="formLabelWidth"
            width="100"
            v-if="
              paragraphObj.type == 4 ||
                (paragraphObj.type == 4 && !paragraphObj.id)
            "
          >
            <el-upload
              class="upload-demo"
              :action="action"
              :multiple="false"
              :on-remove="handleRemove"
              :on-success="updateImgSuccess"
              :file-list="fileList"
              list-type="picture"
              name="formData"
            >
              <el-button size="small" type="primary">上传视频</el-button>
              <div slot="tip" class="el-upload__tip">
                服务器容量有限,请谨慎上传
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            label="副标题"
            :label-width="formLabelWidth"
            v-if="
              paragraphObj.type == 1 ||
                (paragraphObj.type == 1 && !paragraphObj.id)
            "
          >
            <el-input
              v-model="paragraphObj.text"
              auto-complete="off"
              type="textarea"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="文字"
            :label-width="formLabelWidth"
            v-if="
              paragraphObj.type == 2 ||
                (paragraphObj.type == 2 && !paragraphObj.id)
            "
          >
            <el-input
              v-model="paragraphObj.text"
              auto-complete="off"
              type="textarea"
            ></el-input>
          </el-form-item>

          <el-form-item label="排序大小" :label-width="formLabelWidth">
            <el-input
              v-model="paragraphObj.sort"
              auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="itemEditVisible = false">取 消</el-button>
          <el-button class="cancelBtn" @click="handleDeleteImgItem" v-if="paragraphObj.id"
            >删 除</el-button
          >
          <el-button class="addBtn" type="primary" @click="handleSaveImgItem">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 代码上传 -->
      <el-dialog
        :visible.sync="codeEditVisible"
        :close-on-click-modal="false"
        top="0"
        :fullscreen="true"
      >
        <div class="codeCenter">
          <div class="codeCenter_left">
            <el-form :model="codeForm" formLabelWidth="200">
              <!-- HTML -->
              <el-form-item>
                <el-input
                  v-model="codeForm.rawHtml"
                  auto-complete="off"
                  type="textarea"
                  :rows="11"
                ></el-input>
              </el-form-item>
              <!-- CSS -->
              <el-form-item>
                <el-input
                  v-model="codeForm.rawCss"
                  auto-complete="off"
                  type="textarea"
                  :rows="12"
                  @input="codeCenter_left"
                ></el-input>
              </el-form-item>
              <!-- 图片 -->
              <el-form-item>
                <el-input
                  v-model="codeForm.linkUrl"
                  auto-complete="off"
                ></el-input>
              </el-form-item>

              <el-upload
                :action="action"
                :multiple="true"
                :on-success="handleSuccess_linkUrl"
                :file-list="fileList"
                list-type="picture"
                name="formData"
              >
                <el-button class="addBtn" size="small" type="primary"
                  >上传图片</el-button
                >
              </el-upload>
            </el-form>
          </div>
          <div class="codeCenter_right_box">
            <!-- <div class="codeCenter_right_title">预览</div> -->
            <div class="codeCenter_right">
              <div><div v-html="codeForm.rawHtml"></div></div>
            </div>
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="codeEditVisible = false"
            >取 消</el-button
          >
          <el-button class="addBtn" type="primary" @click="handleSaveCode"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { selectCategory } from "@/services/articleManage/articleCategory";
import { selectAuthor } from "@/services/articleManage/author";
import {
  selectArticleManage,
  addArticleManage,
  updateArticleManage,
  deleteArticleManage,
  selectParagraph,
  addParagraph,
  updateParagraph,
  deleteParagraph,
  updateRecommend,
} from "@/services/articleManage/list";
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import listHeader from "./listHeader";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  created() {
    this.getListData();
    this.selectAuthor();
    this.selectCategory();
  },

  data() {
    return {
      loading: false,
      listQuery: {
        type: "",
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      categoryArr: [],
      detailsImg: [],
      paragraphData: [],
      goodsTitle: "",
      tableData: [],
      // 一级文章 二级文章 文章
      selectArticleManage: "",
      select_two_id: "",
      statusList: [
        { id: 0, name: "显示" },
        { id: 1, name: "隐藏" },
      ],
      authorList: [],
      //文章类型: 1链接文章 2自主上传文章
      typeList: [
        { id: 1, name: "链接文章" },
        { id: 2, name: "自主上传文章" },
        { id: 3, name: "代码上传文章" },
      ],
      paragraphTypeList: [
        { id: 1, name: "副标题" },
        { id: 2, name: "文字" },
        { id: 3, name: "图片" },
        { id: 4, name: "视频" },
      ],
      categoryArr_one: [], //遍历一级文章
      parentId: "", //增加二级文章时选择的一级文章id
      isaddArticleManageOne: 1, //增加一级文章还是二级文章
      dataList: [],
      goodsArr: [],
      dialogFormVisible: false,
      dialogParagraphVisible: false,
      itemEditVisible: false,
      paragraphObj: {},
      inpitTypeName: "",
      formLabelWidth: "120px",
      // action: "http://127.0.0.1:6688/uploadFile/add",
      action: IP + "/uploadFile/add",
      fileList: [],
      pageCount: "",
      pageSize: 8,
      dialogForm: {},
      dialogParagraphForm: {},
      shuf: [],
      cover: [],
      describe: [],

      codeEditVisible: false,
      codeForm: {
        linkUrl: "",
      },
    };
  },
  computed: {
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    selectCategory() {
      // 查询一级分类
      selectCategory({
        pageNumber: 1,
        pageSize: 100,
      }).then((res) => {
        this.categoryArr = res.data;
      });
    },
    /* 查询文章列表 */
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      // listQuery.store_id =
      //   listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectArticleManage(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 查询作者 */
    async selectAuthor() {
      const { data, code, count } = await selectAuthor({
        name: "",
        pageNumber: 1,
        pageSize: 100,
      });
      if (code === 200) {
        this.authorList = data;
      }
    },
    tagsList(tags) {
      // console.log("tags", tags);
      return tags?.split(",") || [];
    },
    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 修改code 上传*/
    handleEditCode(row) {
      this.codeEditVisible = true;
      this.codeForm = JSON.parse(JSON.stringify(row));
      this.codeCenter_left();
    },
    handleSaveCode() {
      updateArticleManage(this.codeForm).then((resp) => {
        console.log(resp);
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.codeEditVisible = false;
          this.getListData();
        }
      });
    },
    codeCenter_left() {
      const styleElement = document.createElement("style");
      styleElement.textContent = `.codeCenter_right{${this.codeForm.rawCss} }`;
      document.head.appendChild(styleElement);
    },

    /* 新增单个段落弹窗 */
    async handleAddItem() {
      this.itemEditVisible = true;
      this.paragraphObj = {};
    },

    /* 编辑单个段落弹窗 */
    handleEditItem(row) {
      console.log(row);
      this.itemEditVisible = true;
      this.paragraphObj = JSON.parse(JSON.stringify(row));
    },

    /* 修改段落 */
    async handleEditParagraph(row) {
      this.dialogParagraphVisible = true;
      this.dialogParagraphForm = JSON.parse(JSON.stringify(row));
      this.dialogForm = JSON.parse(JSON.stringify(row));
      //根据文章id查询段落
      const result = await selectParagraph({ articleID: row.id });
      this.paragraphData = result.data;
    },

    /* 保存段落 */
    async handleSaveImgItem() {
      //       addParagraph,
      // updateParagraph,
      console.log(this.paragraphObj, this.dialogParagraphForm.id);
      if (this.paragraphObj?.id) {
        const updateRes = await updateParagraph(this.paragraphObj);
      } else {
        const addRes = await addParagraph({
          ...this.paragraphObj,
          articleID: this.dialogParagraphForm.id,
        });
      }
      this.$message({
        type: "success",
        message: "保存段落成功!",
      });
      this.itemEditVisible = false;
      const result = await selectParagraph({
        articleID: this.dialogParagraphForm.id,
      });
      this.paragraphData = result.data;
    },

    /* 删除段落图片 */
    handleDeleteImgItem() {
      console.log(this.paragraphObj.id);
      this.$confirm("此操作将永久删除该图片吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteParagraph({ id: this.paragraphObj.id }).then(async (resp) => {
            console.log(resp);
            this.$message({
              type: "success",
              message: "删除段落成功!",
            });
            this.itemEditVisible = false;
            const result = await selectParagraph({
              articleID: this.dialogParagraphForm.id,
            });
            this.paragraphData = result.data;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /* 编辑段落图片成功 */
    updateImgSuccess(e) {
      this.fileList = [];
      this.paragraphObj.imgUrl = e.data;
    },

    handleRemove(e, fileList) {
      console.log("移除---------------", e, fileList);
    },

    /* 设置推荐 */
    recommendChange(row, val) {
      this.$confirm(`是否确认${val == "1" ? "设置" : "关闭"}推荐？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (val === true) {
          row.recommend = 1;
        } else {
          row.recommend = 0;
        }
        const result = await updateRecommend({
          id: row.id,
          recommend: row.recommend,
        });
        if (result.code === 200) {
          this.$message({
            type: "success",
            message: "操作成功",
            offset: 100,
          });
          this.getListData();
        } else {
          this.$message({
            type: "error",
            message: "操作失败",
            offset: 100,
          });
          if (val == "0") {
            row.recommend = 1;
          } else {
            row.recommend = 0;
          }
        }
      });
    },

    /* 上传信息图片成功 */
    handleSuccess_cover(e) {
      console.log("上传成功---------------", e.data);
      this.dialogForm.img = e.data;
    },

    handleSuccess_linkUrl(e) {
      console.log("上传成功---------------", e.data);
      this.codeForm.linkUrl = e.data;
      this.fileList = [];
    },

    /* 新增 */
    handleAdd(type) {
      this.dialogFormVisible = true;
      this.inpitTypeName = "新增文章";
      this.fileList = [];
      this.dialogForm = {};
      this.isaddArticleManageOne = 1;
    },

    /* 修改文章信息弹窗 */
    handleEdit(row) {
      this.fileList = [];
      this.dialogFormVisible = true;
      this.inpitTypeName = "修改文章";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },

    /* 保存文章信息 */
    save() {
      console.log("this.dialogForm", this.dialogForm);
      //必填字段校验 !this.dialogForm.img
      if (
        !this.dialogForm.categoryID ||
        !this.dialogForm.categoryName ||
        !this.dialogForm.detail ||
        !this.dialogForm.authorId ||
        !this.dialogForm.type
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      // if (this.dialogForm.store_id.length > 0) {
      //   console.log("this.dialogForm.store_id=====", this.dialogForm.store_id);
      //   this.dialogForm.store_id = this.dialogForm.store_id.join(",");
      // }
      if (this.inpitTypeName == "新增文章") {
        addArticleManage({ ...this.dialogForm, type: 1 }).then((res) => {
          console.log(res);
          if (res.code) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
      if (this.inpitTypeName == "修改文章") {
        updateArticleManage(this.dialogForm).then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(
        `此操作将永久删除'${row.categoryName}'文章吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteArticleManage({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.diseaseList {
  width: 100%;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  /* max-width: 1405px; */
  margin: 10px auto;
}

.goodsTitle:hover {
  cursor: pointer;
}
.upload-demo {
  margin: 20px;
}

/* 详情页 */
.detailsImgBox {
  width: 90%;
  margin: 0 auto 10px;
  max-height: 70vh;
  overflow-y: scroll;
}

/* .imgUrlBoxShow {
  display: flex;
}
.imgUrlBoxShow > div {
  margin: 0px 10px 0 0;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px dotted rgb(93, 93, 93);
  padding: 30px 0;
}
.imgUrlBoxShow > div:hover {
  cursor: pointer;
} */
.detailsImgBoxShow > div {
  padding: 20px;
  border: 1px dotted rgb(93, 93, 93);
  margin: 0 auto;
}
.detailsImgBoxShow > div:hover {
  cursor: pointer;
}
.detailsImgBoxShow > div img {
  width: 50%;
  margin: 0 auto;
}

/* 按钮 */
.dialog-footer {
  margin-top: -10px;
  display: flex;
  justify-content: center;
}
.codeCenter {
  display: flex;
  justify-content: space-around;
}
.codeCenter_left {
  width: 35%;
  height: 75vh;
  overflow-y: scroll;
}

.codeCenter_right_box {
  width: 60%;
  height: 75vh;
}
.codeCenter_right_title {
  margin: 10px 0;
}
.codeCenter_right {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px dashed rgba(1, 1, 1, 0.675);
}
</style>
